import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import FullPageSlider from "../components/FullPageSlider/FullPageSlider"
import FullPageSliderWrapper from "../components/FullPageSlider/FullPageSliderWrapper"
import FullPageSliderContent from "../components/FullPageSlider/FullPageSliderContent"
import { Helmet } from 'react-helmet'
import GoogleMap from '../components/GoogleMap';


const DomekPage = ({ data }) => {
    return (
        <Layout>
            <Helmet>
                <title>Chata pod Jałowcem - zapraszamy do kontaktu</title>
                <meta name="description" content="Skontaktuj się z nami! Numer telefonu kontaktowego: +48 500 026 168. Adres email: samieckrystyna@gmail.com" />
            </Helmet>
            <FullPageSliderWrapper>
                <FullPageSlider images={data.domekBackgrounds.nodes}></FullPageSlider>
                <FullPageSliderContent>
                    <h1>Skontaktuj się z nami</h1>
                    <h3>Numer telefonu kontaktowego:<br />+48 500 026 168</h3>
                    <h3>Adres email:<br />samieckrystyna@gmail.com</h3>
                </FullPageSliderContent>
            </FullPageSliderWrapper>
            <GoogleMap />
        </Layout>
    )
}

export const query = graphql`
query {
  domekBackgrounds: allFile (filter: {sourceInstanceName: {eq: "homeBackgrounds"}}, sort:{fields: [name], order:ASC}){
      nodes {
      childImageSharp {
          gatsbyImageData(
              width: 1920
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
}
`

export default DomekPage
