import React from "react";
import GoogleMapReact from 'google-map-react';
import styled from "styled-components"
import pin from "../images/pin.png";

const markerStyle = {
    position: "absolute",
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -100%)"
};

// const StyledMapMarker = styled(MapMarker)`
// `

const MapMarker = ({ children }) => <div>{children}</div>;

const StyledWrapper = styled.div`
`

export default function GoogleMap() {
    const defaultProps = {
        center: {
            lat: 49.63942037003346,
            lng: 18.93589367799821
        },
        zoom: 15
    };

    return (
        <StyledWrapper style={{ height: '60vh', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyBHq3MR-arrdZpYpVKTFqPD5LPhgUag6cw" }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
            >
                {/* <MapMarker lat={defaultProps.center.lat} lng={defaultProps.center.lng}></MapMarker> */}
                {/* <div lat={defaultProps.center.lat} lng={defaultProps.center.lng}>
                    <img style={markerStyle} src={pin} alt="pin" />
                </div> */}

                <MapMarker lat={defaultProps.center.lat} lng={defaultProps.center.lng} >
                    <img style={markerStyle} src={pin} alt="pin" />
                </MapMarker>
            </GoogleMapReact>
        </StyledWrapper>
    );
}